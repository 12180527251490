<template>
  <section :class="$style.article">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <div :class="$style.wrapper">
        <el-button type="primary" @click="submitForm">
          Сохранить 
        </el-button>
        <el-button type="primary" @click="submitFormAndExit">
          Сохранить и вернуться
        </el-button>
      </div>
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" @input="handleNameInput" />
      </el-form-item>
      <el-form-item label="Название ссылки" prop="slug">
        <el-input v-model="form.slug" />
      </el-form-item>
      <el-form-item label="Фото статьи" prop="images">
        <Uploader
          :limit="3"
          :files="form.images ?? []"
          @upload="uploadImages"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Категории" prop="categories">
        <el-select
          v-model="form.categories"
          multiple
          filterable
          clearable
          placeholder="Выберите категории"
          :class="$style.categories"
        >
          <el-option
            v-for="item in articleCategories"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Текст кнопки" prop="caption">
        <el-input v-model="form.redirectAction.caption" />
      </el-form-item>
      <el-form-item label="Ссылка кнопки" prop="linkTo">
        <el-input v-model="form.redirectAction.linkTo" />
      </el-form-item>
      <el-form-item label="Тип кнопки" prop="type">
        <el-input v-model="form.redirectAction.type" />
      </el-form-item>
      <el-form-item label="Дата публикации" prop="publishedAt">
        <el-date-picker
          v-model="form.publishedAt"
          type="date"
          :class="$style.date"
          placeholder="Выберите дату"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Отображать на сайте">
        <el-checkbox v-model="form.isActive" />
      </el-form-item>
      <el-form-item label="Описание" prop="content" />
      <TextEditor :value.sync="form.content" />
      <PhotoGallery
        :photos.sync="form.gallery.photos"
        :direction.sync="form.gallery.direction"
        :margin.sync="form.gallery.margin"
        :columns.sync="form.gallery.columns"
        :target-row-height.sync="form.gallery.targetRowHeight"
        :limit.sync="form.gallery.limit"
      />
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import Uploader from '@/components/moleculs/AddwineUploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import PhotoGallery from '@/components/moleculs/PhotoGallery.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'

import { transliterate } from '@/helpers/slug'
import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { SEO_TYPES } from '@/constants/seo'

export default {
  components: {
    TextEditor,
    Uploader,
    PhotoGallery,
    SeoBlock,
  },
  SEO_TYPES,
  ADDEVENT_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      articleCategories: [],
      form: {
        images: [],
        name: '',
        slug: '',
        category: '',
        categories: [],
        content: '',
        isActive: false,
        publishedAt: '',
        redirectAction: {
          linkTo: '',
          caption: '',
          type: '',
        },
        gallery: {
          targetRowHeight: 300,
          columns: 1,
          margin: 0,
          direction: 'row',
          limit: 0,
          photos: [],
        },
        seo: {
          setDefault: false,
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      datePickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(0, 0, 0, 0)
          return date < today
        },
      },
      rules: {
        images: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        category: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        content: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        publishedAt: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        categories: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  async created() {
    await Promise.all([this.getArticle(), this.getArticlesCategories()])
  },
  methods: {
    async getArticle() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.ArticlesActions.getOne(
          this.$route.params.id,
        )

      loading.close()

      if (error) return

      this.form = {
        ...value,
        redirectAction: {
          linkTo: value.redirectAction?.linkTo ?? '',
          caption: value.redirectAction?.caption ?? '',
          type: value.redirectAction?.type ?? '',
        },
        categories: value.categories?.map(({ id }) => id),
        gallery: {
          ...value.gallery,
          photos:
            value.gallery?.photos?.map((photo) => ({
              ...photo,
              src: this.$configData.s3_link + photo.src,
              original: photo.src,
            })) ?? [],
          images: value.images ?? [],
        },
        seo: value?.seo
          ? {
              ...value?.seo,
              setDefault: value?.seo?.type === SEO_TYPES.TEMPLATE,
              ogImage: value?.seo?.ogImage
                ? {
                    ...value?.seo?.ogImage,
                    origin: value?.seo?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : {
              ...this.form?.seo,
              setDefault: false,
            },
      }
    },
    async getArticlesCategories() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.ArticleCategoriesActions.getList()

      loading.close()

      if (error) return

      this.articleCategories = value.data
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    uploadImages(images) {
      this.form.images = images
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        let data = {
          ...this.form,
          setDefault: this.form?.seo?.setDefault,
          seo: this.form?.seo?.setDefault
            ? null
            : {
                ...this.form.seo,
                type: SEO_TYPES.DEFAULT,
                ogImage: {
                  ...this.form?.seo?.ogImage,
                  caption: this.form?.seo?.title,
                },
              },
        }

        if (
          !data?.redirectAction?.type ||
          !data?.redirectAction?.linkTo ||
          !data?.redirectAction?.caption
        ) {
          data.redirectAction = null
        }

        if (data.images?.length) {
          data.images =
            data.images?.map((image) => ({
              id: image?.id ?? null,
              caption: image.caption,
              origin: image.original,
              order: image.order_field ?? 1,
            })) ?? []
        }

        if (data.gallery?.photos?.length) {
          data.gallery = {
            ...data.gallery,
            photos: data.gallery?.photos?.map((photo) => ({
              id: photo.id,
              src: photo.original,
              width: photo.width,
              height: photo.height,
            })),
          }
        }

        const { error } =
          await delivery.AddeventServiceCore.ArticlesActions.update(
            this.$route.params.id,
            data,
          )

        loading.close()

        if (error) {
          this.showNotification('Ошибка редактирования статьи', 'error')
          return
        }

        this.showNotification('Статья успешно отредактирована', 'success')
      })
    },
    async submitFormAndExit() {
      await this.submitForm()
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="scss" module>
.article {
  padding: 1rem;
  .form {
    .wrapper {
      @include stickyWrapper;
      a {
        padding: 0 0.625rem;
        button {
          height: 100%;
        }
      }
    }
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }

    .date {
      width: 100%;
    }
    .categories {
      width: 100%;
    }
  }
}
</style>
